@import "../../styles/vars.scss";

$gutters: (2 * $layout-content-padding-num) + "px";

.field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
}

.field__label {
  font-size: 1rem;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 0;
  height: 100%;
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.field__cell {
  flex: 1;

  &:first-child {
    margin-right: 0.125rem;
  }

  &:last-child {
    margin-left: 0.125rem;
  }
}

.field__input__container {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-shrink: 0;
  min-width: 50%;
}

.field__input,
.field__select {
  box-sizing: border-box;
  font-size: 1rem;
  height: 2rem;
  border: none;
  border-bottom: solid 1px var(--inactive-color);
  background-color: var(--bg-color);
  color: var(--text-color);
  width: 100%;
  border-radius: 0;
  appearance: none;
  text-transform: capitalize;

  &:focus {
    outline: none;
    border-bottom: solid 1px var(--primary-color-hover);
  }
}

.field__input {
  padding: 0.25rem 0;
}

.field__select {
  padding: 0.22rem 0;
  border-radius: 0;
  appearance: none;
  background-image: var(--select-arrow);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7rem top 50%, 0 0;
  background-size: 0.65rem auto, 100%;
  padding-right: 1.75rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:focus {
    background-image: var(--select-arrow-focus);
  }

  &::-ms-expand {
    display: none;
  }
}

// NOTE: to prevent browser/password managers from injecting icons
.field__numeric {
  background: none !important;
}

// NOTE: to prevent browser/password managers from injecting icons
img[id^="__lpform_"] {
  display: none !important;
}

.field__input--cell_width_fix,
.field__select--cell_width_fix {
  width: calc(100% - 0.5rem);
}

.field__checkbox + label {
  display: block;
  margin: 0.2rem;
  cursor: pointer;
  padding: 0.2rem;
}

.field__checkbox {
  display: none;
}
