.callout {
  margin-bottom: 2rem;
  box-sizing: border-box;
  position: relative;

  &:after {
    clear: both;
  }
}

.callout__icon {
  display: inline-block;
  line-height: 1.5rem;
  position: absolute;
  top: 0;
}

.callout__blurb {
  display: inline-block;
  line-height: 1.5rem;
  margin: 0 0 0 2rem;
}
