html {
  font-size: 100%;

  &.font-size-m {
    font-size: 100%;
  }

  &.font-size-xs {
    font-size: 85.5%;
  }

  &.font-size-s {
    font-size: 92.5%;
  }

  &.font-size-l {
    font-size: 107.5%;
  }

  &.font-size-xl {
    font-size: 115%;
  }
}
