@function generate-arrow($fill-color) {
  $unhexed: str-slice(
    #{$fill-color},
    2
  ); // remove the OCTOTHORPE, as it's already URL-encoded in the SVG string
  @return "data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23#{ $unhexed }%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E";
}

:root {
  --bg-color: #{$theme-bg-color};
  --text-color: #{$theme-text-color};
  --text-color-contrast: #{$theme-text-color-contrast};
  --text-muted-color: #{lighten($theme-text-color, 35%)};
  --primary-color: #{$theme-primary-color};
  --primary-color-hover: #{$theme-primary-hover};
  --primary-color-active: #{$theme-primary-active};
  --primary-text-color: #{$theme-primary-text-color};
  --danger-color: #{$theme-danger-color};
  --danger-color-hover: #{$theme-danger-hover};
  --danger-color-active: #{$theme-danger-active};
  --danger-text-color: #{$theme-danger-text-color};
  --warning-color: #{$theme-warning-color};
  --warning-text-color: #{$theme-warning-color};
  --inactive-color: #{$theme-inactive-color};
  --select-arrow: url(#{ generate-arrow($theme-inactive-color) });
  --select-arrow-focus: url(#{ generate-arrow($theme-primary-hover) });
  --divider-color: #{$theme-inactive-color};
  --menu-bg-color: #{$theme-menu-bg-color};
  --menu-cover-bg-color: #{$theme-cover-bg-color};
}

.theme-dark {
  --bg-color: #{darken($theme-bg-color, 80%)};
  --text-color: #{invert($theme-text-color)};
  --text-color-contrast: #{$theme-text-color-contrast};
  --text-muted-color: #{invert(lighten($theme-text-color, 35%))};
  --primary-color: #{lighten($theme-primary-color, 25%)};
  --primary-color-hover: #{lighten($theme-primary-hover, 25%)};
  --primary-color-active: #{lighten($theme-primary-active, 25%)};
  --primary-text-color: #{darken($theme-primary-text-color, 75%)};
  --danger-color: #{lighten($theme-danger-color, 15%)};
  --danger-color-hover: #{lighten($theme-danger-hover, 15%)};
  --danger-color-active: #{lighten($theme-danger-active, 15%)};
  --danger-text-color: #{darken($theme-danger-text-color, 75%)};
  --warning-color: #{lighten($theme-warning-color, 20%)};
  --warning-text-color: #{lighten($theme-warning-color, 20%)};
  --inactive-color: #{$theme-inactive-color};
  --select-arrow: url(#{ generate-arrow($theme-inactive-color) });
  // NOTE: prettier borks paramter formatting for nested functions
  /* prettier-ignore */
  --select-arrow-focus: url(#{ generate-arrow(lighten($theme-primary-hover, 25%))});
  --divider-color: #{$theme-inactive-color};
  --menu-bg-color: #{$theme-menu-bg-color};
  --menu-cover-bg-color: #{$theme-cover-bg-color};
}
