.form {
  display: inline;
}

.form__fieldset {
  width: 100%;
  border: 0;
  margin: 0 0 1rem 0;
  padding: 0;
}

.form__fieldset__legend {
  width: 100%;
  font-weight: 500;
  display: block;
  width: 100%;
  margin-bottom: 0.75rem;
  padding-bottom: 0.5rem;
  border-bottom: solid 1px var(--divider-color);
  text-transform: capitalize;
}

.button {
  font-family: "Roboto", sans-serif; // TODO: figure out why this isn't being inherited
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  border-radius: 0.25rem;
  user-select: none;
  outline: none;
  min-height: 2.5rem;
  padding: 1rem;
  letter-spacing: 0.1rem;
}

.button--primary {
  background-color: var(--primary-color);
  color: var(--primary-text-color);

  &:active {
    background-color: var(--primary-color-active);
  }

  &:focus {
    outline: 1px var(--primary-color-hover);
  }

  &:hover {
    background-color: var(--primary-color-hover);
  }
}

.button--danger {
  background-color: var(--danger-color);
  color: var(--danger-text-color);

  &:hover {
    background-color: var(--danger-color-hover);
  }

  &:active {
    background-color: var(--danger-color-active);
  }

  &:focus {
    outline: 1px var(--danger-color-active);
  }
}

.button--yuge {
  width: 100%;
  font-size: 1.25rem;
}

.info {
  margin-top: 0;
}

.info-label {
  font-style: italic;
  display: inline-block;

  &:after {
    content: ": ";
    padding-right: 0.25rem;
  }
}

.app-info {
  margin-top: 1rem;
}

.app-info__key {
  font-weight: 500;
  padding-right: 1rem;
  vertical-align: top;
  text-transform: capitalize;
}

.button-container {
  height: 100%;
  display: flex;

  & > .button {
    margin-left: 0.75rem;
    margin-right: 0.75rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.button--flex-1 {
  flex: 1;
}

.button--flex-2 {
  flex: 2;
}

.button--flex-3 {
  flex: 3;
}
