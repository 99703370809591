.feedback {
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.feedback__item {
  margin-bottom: 0.5rem;
}
