.modal {
  position: fixed;
  z-index: 21;
  background-color: var(--bg-color);
  width: 90%;
  top: 25%;
  left: 5%;
  border-radius: 0.25rem;
}

.modal__body {
  padding: 0.5rem 1rem 1rem 1rem;
}

.modal__footer {
  padding-top: 1rem;
  text-align: right;
}

.modal__cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--menu-cover-bg-color);
  z-index: 20;
}

.modal__heading {
  display: flex;
  align-items: center;
}

.modal__heading__icon {
  margin-right: 0.25rem;
}

.scroll-lock {
  overflow: hidden;
}
