@import "../../styles/vars.scss";

#GlobalWarning {
  display: none;
  color: var(--warning-text-color);
  border: solid 1px var(--warning-color);
  padding: 1rem;

  & > .callout__icon {
    top: 1rem;
  }
}

// Show only on mobile screens in landscape orientation
@media screen and (hover: none) and (orientation: landscape) {
  #GlobalWarning {
    margin: $layout-content-padding;
    display: block;
  }
}
