@import "../../styles/vars.scss";

.app-menu__cover {
  position: fixed;
  top: $layout-header-height;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--menu-cover-bg-color);
  z-index: 9;
  display: none;
}

.app-menu__cover--active {
  display: block;
}

.app-menu {
  background-color: var(--menu-bg-color);
  height: calc(100% - #{$layout-header-height});
  position: fixed;
  right: 0;
  top: $layout-header-height;
  transition: transform 0.2s ease-in-out, visibility 0s;
  visibility: visible;
  min-width: 45%;
  max-width: 75%;
  z-index: 10;
}

.app-menu--closed {
  transform: translateX(100%);
  transition: transform 0.2s ease-in-out, visibility 0s 0.2s;
  visibility: hidden;
}

.app-menu__list {
  margin: 0;
  padding: 1rem;
  list-style: none;
  list-style-type: none;
}

.app-menu__list__item {
  margin-bottom: 1rem;
  color: var(--text-color);
}

.app-menu__list__item:last-child {
  margin-bottom: 0;
}

.app-menu__list__item__link,
.app-menu__list__item__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-color);
  font-size: 1rem;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  appearance: none;
  border: 0;
  outline: none;
  background-color: transparent;
}

.app-menu__list__item__link {
  text-decoration: none;
  user-select: none;
}

.app-menu__list__item__label {
  padding-left: 0.5rem;
  color: var(--text-color-contrast);
  text-transform: capitalize;
}

.app-menu__list__item__icon {
  color: var(--text-color-contrast);
}

.app-menu__list__item__link--active,
.app-menu__list__item__link--active .app-menu__list__item__label,
.app-menu__list__item__link--active .app-menu__list__item__icon {
  color: var(--primary-color);
  white-space: nowrap;
  text-overflow: ellipsis;
}

.app-menu__list-divider {
  height: 1px;
  border: none;
  margin: 0;
  background-color: var(--divider-color);
}

.app-menu__version {
  bottom: 1rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  text-align: right;
}
