@import "./styles/vars.scss";
@import "./styles/themes.scss";
@import "./styles/util.scss";
@import "./styles/typography.scss";
@import "./styles/forms.scss";
@import "./styles/locale/en.scss";
@import "./styles/locale/es.scss";
@import "./styles/locale/pl.scss";
@import "./styles/font-sizes.scss";

html {
  overscroll-behavior-y: contain;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
}

#root,
#router {
  display: inline;
}

html,
body,
.app {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

main {
  background-color: var(--bg-color);
}

a,
a:link,
a:visited {
  color: var(--primary-color);
}

a:hover,
a:active {
  color: var(--primary-color-active);
}
